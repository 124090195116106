.customisedInt-base{
    background-image: linear-gradient(to left,#8066665b, #6e645f3f);
    width: 100%;
    position: relative;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 15px;
    justify-content: space-between;
}

.custom-head{
    width: 35%;
    height: 100%;
    padding-top: 0px;
    padding-left: 130px;
    
}

.custom-grid{
    height: 100%;
    width: auto;

    display: grid;
    grid-template-columns: 320px 320px 320px;
    grid-gap: 20px;
    padding: 20px;
    padding-left: 50px;
}

.grid-item {
    text-align: center;
    position: relative;
}

.custom-img{
    width: 100%;
    height: auto;
    border-radius: 2px;
}

.custom-img-over{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;

    padding-top: 10px;

    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px!important;
    color: rgb(194, 194, 194);
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.788));
}

.custom-title{
    color: #5d7680;
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    line-height: 1.1;
    font-size: 90px!important;

    padding-top: 10px;
}

.custom-title > div >.highlight{
    color: #609b9b;
}

.custom-subtag{
    color: #567272;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    line-height: 1.4;
    font-size: 22px!important;

    padding-top: 16px;
}

.btn-custom-types{
    border: none;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 19px;
    padding: 3px 7px;
    border-radius: 3px;
    background-color: #fcf2e7;
    color: #3f7e7e!important;

    transition: all 0.3s;
}

.btn-custom-types:hover{
    background-color: #d1c8bf;
    color: #567272;
    transition: all 0.3s;
}

.custom-text{
    color: #768080;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.4;
    font-size: 20px!important;

    padding-top: 21px;
}

.btn-custom-main{
    font-weight: 500;
    margin-top: 10px;
    border: none!important;
    padding: 3px 20px!important;
    font-size: 25px!important;
    font-weight: 500;
    color: #ffffff!important;
    background-color: #5d9fb9!important;

    margin-top: 35px;
    margin-bottom: 20px;
}

.btn-custom-main:hover{
    background-color: #4b859b!important;
    color: #e6dfd6!important;
}



/*Breakpoints*/
@media(max-width: 1670px){
    .custom-grid{
        grid-template-columns: 300px 300px 300px;
        grid-row-gap: 30px;
    }

    .custom-title{
        font-size: 80px!important;
    }

    .custom-text{
        font-size: 20px!important;
    }

    .btn-custom-types{
        font-size: 18px;
    }

    .custom-subtag{
        padding-top: 25px;
    }
}

@media(max-width: 1515px){
    .custom-title{
        font-size: 70px!important;
    }

    .custom-text{
        font-size: 19px!important;
    }

    .btn-custom-main{
        font-size: 23px!important;
        margin-top: 30px;
    }
}

@media(max-width: 1473px){
    .custom-grid{
        grid-template-columns: repeat(3,300);
        grid-row-gap: 5vw;
    }
}

@media(max-width: 1450px){
    .custom-grid{
        grid-template-columns: auto auto auto;
        width: 100%;
        grid-gap: 40px;
        padding: 30px;
        margin-top: 20px;
    }

    .custom-head{
        width: 50%;
        padding-top: 15px;
        padding-left: 30px;
    }

    .customisedInt-base{
        flex-wrap: wrap;
        padding: 0;
    }

    .custom-title{
        font-size: 80px!important;
    }

    .custom-subtag{
        width: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        text-align: right;
    }

    .custom-text{
        font-size: 22px!important;
        text-align: right;
        width: 50%;
        position: absolute;
        top: 155px;
        right: 30px;
    }

    .btn-custom-types{
        font-size: 20px;
    }

    .btn-custom-main{
        font-size: 25px!important;
    }
}

@media(max-width: 1090px){
    .custom-text{
        padding-left: 50px;
    }
}

@media(max-width: 1060px){
    .custom-grid{
        grid-template-columns: 300px 300px;
        width: auto;
        grid-gap: 20px;
        padding: 30px;
        margin-top: 0;
    }

    .custom-head{
        width: auto;
        padding-top: 15px;
    }

    .customisedInt-base{
        flex-wrap: nowrap;
        padding: 0;
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .custom-title{
        font-size: 60px!important;
        text-align: justify;
    }

    .custom-subtag{
        position: relative;
        text-align: left;
        top: 0;
        right: 0;
        margin: 0;
        margin-top: 10px;
        width: auto;
    }

    .custom-text{
        font-size: 22px!important;
        text-align: left;
        position: relative;
        width: auto;
        top: 0;
        right: 0;
        padding: 0;
        margin-top: 25px;
    }

    .btn-custom-types{
        font-size: 20px;
    }

    .btn-custom-main{
        font-size: 25px!important;
        margin-top: 40px;
    }
}

@media(max-width: 930px){
    .custom-text{
        font-size: 20px!important;
    }

    .customisedInt-base{
        padding-bottom: 30px;
    }
}

@media(max-width: 1020px){
    .custom-grid{
        grid-template-columns: auto auto;
        width: 100%;
        grid-gap: 40px;
        padding: 30px;
        margin-top: 0px;
    }

    .custom-head{
        width: 50%;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .customisedInt-base{
        flex-wrap: wrap;
        padding: 0;
    }

    .custom-title{
        font-size: 7.5vw!important;
    }

    .custom-subtag{
        width: 45%;
        position: absolute;
        top: 5px;
        right: 20px;
        text-align: right;
    }

    .custom-text{
        font-size: 20px!important;
        text-align: right;
        width: 50%;
        position: absolute;
        top: 175px;
        right: 30px;
    }

    .btn-custom-types{
        font-size: 20px;
    }

    .btn-custom-main{
        font-size: 25px!important;
    }
}

@media(max-width: 845px){
    .custom-grid{
        margin-top: 30px;
    }
}

@media(max-width: 816px){
    .custom-text{
        top: 175px;
    }

    .custom-grid{
        margin-top: 40px;
    }
}


@media(max-width: 780px){
    .custom-text{
        position: relative;
        top: 0;
        right: 0;
        text-align: left;
    }

    .custom-subtag{
        width: 30%;
    }

    .btn-custom-types{
        margin-bottom: 15px;
    }

    .custom-title{
        font-size: 60px!important;
    }

    .custom-head{
        width: 100%;
        padding-bottom: 0;
    }

    .custom-grid{
        margin-top: 0px;    
    }
}

@media(max-width: 620px){
    .custom-grid{
        grid-template-columns: auto;
        width: 100%;
        grid-gap: 40px;
        padding: 30px;
        margin-top: 20px;
    }
}

@media(max-width: 550px){
    .custom-title{
        text-align: center;
        font-size: 35px!important;
        padding-top: 2px;
    }

    .custom-text{
        text-align: center;
        width: 100%;
        left: 0;
        font-size: 16px!important;
        position: static;
        margin-top: 10px;
    }

    .custom-subtag{
        position: static;
        width: 100%;
        text-align: center;
        margin-top: 0;
        padding-top: 10px;
    }

    .custom-head{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }

    .btn-custom-types{
        font-size: 15px!important;
        margin-bottom: 5px;
    }

    .custom-grid{
        margin-top: 0;
        grid-gap: 10px;
        padding-top: 20px;
        padding-bottom: 15px;
    }

    .btn-custom-main{
        margin-top: 15px;
        margin-bottom: 0px;
        font-size: 20px!important;
    }

    .custom-img-over{
        font-size: 16px!important;
    }

}