.process-base{
    background-image: linear-gradient(to left,#eed8cc, #f1dbce);
    width: 100%;
    position: relative;

    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 50px;

}

.process-title{
    color: #967A7A;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 50px!important;
    padding-bottom: 5px;
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 15px;
    text-align: left;
}

.process-container{
    display: grid;
    grid-template-columns: repeat(6, minmax(0,1fr));
    height: 180px;
}

.process-item{
    position: relative;
    display: flex; 
    align-items: center;
    justify-content: center;
    text-align: center;
}

.p-text{

    text-align: right;

    z-index: 20;
    position: absolute;

    color: #e4dede;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;

    bottom: 15px;
    right: 15px;

    width: 250px;
}

.process-pronoun{
    position: absolute;
    top: 12px;
    left: 15px;
    font-size: 70px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    line-height: 0.8;

    z-index: 10;
    color: #b99c9cec;
}

#p-1{
    font-size: 25px;
}
#p-2{
    font-size: 19px;
}
#p-3{
    font-size: 23px;
}
#p-4{
    font-size:18px;
}
#p-5{
    font-size: 22px;
}
#p-6{
    font-size: 45px;
    color: #dbdbdbe1;
    padding-top: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    line-height: 1;
}

.process-color-A{
    background-color: #AA8989;
}

.process-color-B{
    background-color: #967A7A;
}

.process-puzzle-key{
    position: absolute;
    height: 45px;
    width: 45px;

    top: 43%;
    left: -10px;

    border-radius: 20px;
}

.borderA{
    border-radius: 3px 0px 0px 3px;
}

.borderB{
    border-radius: 0px 3px 3px 0px;
}


/* Breakpoints */
@media(max-width: 1860px){
    .process-container{
        height: 210px;
    }

    #p-4{
        width: 75%;
    }
}

@media(max-width: 1667px){
    .process-container{
        height: 240px;
    }

    #p-2{
        width: 75%;
    }

    #p-5{
        width: 72%;
    }
}

@media(max-width: 1500px){
    #p-6{
        font-size: 40px;
        color: #dbdbdbe1;
        padding-top: 10px;
        padding-left: 5px;
        font-family: 'Open Sans',sans-serif;
        font-weight: 700;
        line-height: 1;
    }
}

@media(max-width: 1490px){
    .process-container{
        height: 270px;
    }

    #p-3{
        width: 80%;
    }
}

@media(max-width: 1420px){
    #p-6{
        padding-left: 0.8vw;
    }
}

@media(max-width: 1300px){
    #p-6{
        padding-left: 1.4vw;
    }
}

@media(max-width: 1235px){
    .process-container{
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
        height: 380px;
    }

    #disA{
        display: none;
    }
}

@media(max-width: 992px){
    .process-container{
        grid-template-rows: minmax(0, 4.7fr) minmax(0, 6fr);
    }
}

@media(max-width: 950px){
    .process-container{
        grid-template-rows: repeat(2,minmax(0, 1fr));
        height: 420px;
    }
}

@media(max-width: 880px){
    .process-container{
        grid-template-rows: repeat(3,minmax(0,1fr));
        grid-template-columns: repeat(2,minmax(0,1fr));
        height: 500px;
    }

    #disA{
        display: block;
        background-color: #967A7A;
    }

    #disB{
        display: none;
    }

    .color-switch-A{
        background-color: #967A7A!important;
    }

    .color-switch-B{
        background-color: #AA8989!important;
    }
}

@media(max-width: 830px){
    .process-container{
        height: 600px;
    }
}

@media(max-width: 660px){
    .process-container{
        grid-template-rows: minmax(0, 1fr) minmax(0, 1.2fr) minmax(0, 1fr) minmax(0, 1.3fr) minmax(0, 1fr) minmax(0, 1fr);
        grid-template-columns: repeat(1,minmax(0,1fr));
        height: 500px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .process-base{
        padding-bottom: 20px;
    }

    .process-pronoun{
        font-size: 25px;
    }

    .process-puzzle-key{
        display: none!important;
    }

    .color-switch-B {
        background-color: #967A7A !important;
    }

    .color-switch-A {
        background-color: #AA8989 !important;
    }

    .process-title{
        text-align: center;
        font-size: 30px!important;
        padding-top: 10px;
        padding-bottom: 5px;
    }

    .process-base{
        padding-left: 20px;
        padding-right: 20px;
    }

    #p-1 {
        font-size: 18px;
        width: 100%;
    }

    #p-2 {
        font-size: 16px;
        width: 80%;
        padding-left: 30px;
    }

    #p-3 {
        width: 100%;
        font-size: 18px;
    }

    #p-4 {
        width: 80%;
        font-size: 16px;
        padding-left: 30px;
    }

    #p-5 {
        width: 100%;
        font-size: 18px;
    }

    #p-6 {
        font-size: 25px;
        color: #dbdbdbe1;
        padding-top: 10px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        line-height: 1;
    }

}