.hotels-base{
    background-color: rgb(235, 238, 238);
    padding-right: 8.5%;
    position: relative;
}

.hotels-container{
    background-color: #8a6638;
    height: fit-content;
    padding: 35px;
    padding-right: 60px;
}

.img-roomNumber{
    position: absolute;
    height: 100%;
    right: 0px;
    bottom: 0;
}

.img-hotelWelcome{
    position: absolute;
    height: 600px;
}

.hotels-grid-container{
    display: grid;
    grid-template-areas: 'header header imgA' 'imgB imgC imgD';
    grid-gap: 20px;
    height: 100%;
}

.itemA { grid-area: header;}
.itemB { grid-area: imgA; }
.itemC { grid-area: imgB; }
.itemD { grid-area: imgC; }
.itemE { grid-area: imgD; }

.hotels-title{
    font-size: 85px;
    font-weight: 800;
    line-height: 1;
    font-family: 'Open Sans';
    color: rgb(255, 244, 228);
}

.itemA{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.hotels-content{
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    color: aliceblue;
    padding-top: 13px;
    max-width: 80%;
    text-align: left;
    padding-left: 2px;
    padding-bottom: 20px;
}

.gridItem{
    position: relative;
}

.gridItem > img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Breakpoints */

@media(max-width: 1800px){
    .hotels-grid-container{
        grid-template-rows: 250px 300px;
    }

    .hotels-base{
        padding-right: 8%;
    }
}

@media(max-width: 1450px){
    .hotels-grid-container {
        grid-template-areas: 'header header''imgA imgB''imgC imgD';
        grid-template-rows: repeat(3,auto);
        grid-gap: 20px;
        height: 100%;
        margin-right: 0;
    }

    .hotels-container{
        height: 100%;
        width: 100%;
        padding-right: 30px;
    }

    .hotels-base{
        padding-right: 0;
    }

    .img-roomNumber{
        display: none;
    }

    .gridItem > img{
        height: 360px;
    }

    .hotels-content{
        padding-bottom: 0;
    }
}

@media(max-width: 992px){
    .hotels-grid-container {
        grid-template-areas: 'header' 'imgA' 'imgB' 'imgC' 'imgD';
        grid-template-rows: repeat(6,auto);
        grid-gap: 20px;
        height: 100%;
        margin-right: 0;
    }

    .hotels-title{
        font-size: 10vw;
    }

    .hotels-content{
        padding: 0;
        padding-top: 10px;
    }

    .hotels-content{
        font-size: 2.5vw;
        text-align: center;
    }

    .itemA{
        align-items: center;
    }

    .hotels-container{
        padding: 20px 25px;
        padding-bottom: 0;
    }
}

@media(max-width: 600px){
    .hotels-content{
        font-size: 16px;
        max-width: 100%;
        padding-bottom: 5px;
    }

    .itemA{
        padding: 0;
    }

    .gridItem > img{
        width: 100%;
        height: auto;
    }

    .hotels-grid-container{
        grid-gap: 10px;
    }

    .hotels-container{
        padding: 15px 20px;
    }

}