html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  /* overflow-y: overlay; */
  overflow-x: hidden;
  font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
}

#root{
  text-align: center;
  width: 100%;
}

/*Scrollbar styling*/
::-webkit-scrollbar {
  width: 5px;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 0.4s;
}

::-webkit-scrollbar-track {
  background: transparent;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 0.4s;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(180, 180, 180,0.5);
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 0.4s;

  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 0.4s;
}

.globalContainer {
  padding-top: 77px;
  width: 100%;
}

@media(max-width: 1580px) {
  .globalContainer {
    padding-top: 72px;
  }
}

@media(max-width:1490px) {
  .globalContainer {
    padding-top: 127px;
  }
}

@media(max-width:870px) {
  .globalContainer {
    padding-top: 72px;
  }
}

@media(max-width: 740px) {
  .globalContainer {
    padding-top: 124px;
  }
}

@media(max-width: 590px) {
  .globalContainer {
    padding-top: 115px;
  }
}