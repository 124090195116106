.header-container {
  height: 77px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  width: 101.5%;
  background-image: linear-gradient(to left, #247dbe, #71bcf1);
}

.site-header {
  background-image: linear-gradient(to left, #247dbe, #71bcf1);
  width: 100%;
  padding: 0 0 0 0 !important;
}

.header-padding {
  padding-top: 17px;
  padding-bottom: 12px;
}

.mx-2-5 {
  margin: 0 0.8rem;
}

.header-right {
  padding-right: 20px;
  text-align: right;
}

.header-left {
  padding-left: 25px;
  text-align: left;
}

.header-button-base {
  border-radius: 5px !important;
  font-weight: 500;
  border: none !important;
  outline: none !important;

  height: 36px;
  padding-right: 10px;
  padding-left: 10px;
  z-index: 99 !important;
  color: #ffffff !important;
}

.btn-nav {
  background: linear-gradient(135deg, #a9d3f1, #82beec);
  box-shadow: 5px 5px 15px #338ac9, -5px -5px 15px #9ed1fa;
  transition: all 0.3s;
}

.btn-nav:hover {
  background: linear-gradient(135deg, #9cc8e7, #95d0fd);
  box-shadow: 2px 2px 10px #338ac9, -2px -2px 10px #9ed1fa;
  transition: all 0.3s;
}

.btn-nav:focus {
  background: linear-gradient(-135deg, #8dcfff, #62b7f8);
  box-shadow: inset 2px 2px 10px #338ac9, inset -2px -2px 10px #9ed1fa;

  transition: all 0.3s;
}

.btn-nav-target {
  background: linear-gradient(135deg, #a9d3f1, #82beec);
  box-shadow: 5px 5px 15px #338ac9, -5px -5px 15px #9ed1fa;
  transition: all 0.3s;
}

.btn-nav-active {
  background: linear-gradient(135deg, #a9d3f1, #82beec);
  box-shadow: 5px 5px 15px #338ac9, -5px -5px 15px #9ed1fa;
  transition: all 0.3s;
}

.btn-nav-inactive {
  background: linear-gradient(135deg, #a9d3f1, #82beec);
  box-shadow: 5px 5px 15px #338ac9, -5px -5px 15px #9ed1fa;
  transition: all 0.3s;
}

.btn-nav-inactive:hover {
  background: linear-gradient(135deg, #9cc8e7, #95d0fd);
  box-shadow: 2px 2px 10px #338ac9, -2px -2px 10px #9ed1fa;
  transition: all 0.3s;
}

.btn-logo {
  height: 36px;
  width: 150px;
  border: none;
  background-image: url("../media/branding/logo.png");
  background-size: cover;
  transition: all 0.4s;
  margin-right: 40px;
}

.btn-logo:hover {
  width: 155px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.4s;
}

.hr-line {
  padding: 0.5px;
  background-color: #dde2e696;
  margin: 0 39px;
  margin-top: 3px;
}

.top-btn-arrow {
  height: 14px;
  width: auto;
  margin-left: 7px;
  margin-top: -1px;
  opacity: 0.7;
}

.hover-flip:hover .top-btn-arrow {
  animation: flip 0.62s ease-in-out;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.hover-shake:hover .top-btn-phone {
  animation: wiggle-wiggle-wiggle 0.42s ease-in-out;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.top-btn-img-home {
  height: 20px;
  width: auto;
  opacity: 0.8;
}

.menu-drop-enter {
  top: -7px;
  left: -256px;
  border-radius: 5px;
  width: 540px;
  height: 36px;
  opacity: 0;
  background-color: #df0c0c00;
  z-index: 999 !important;
}

.menu-drop-enter-active {
  top: 40px;
  left: -256px;
  border-radius: 5px;
  width: 540px;
  height: auto;
  opacity: 1;
  background-color: #74baf0fa;
  transition: all 400ms;
}

.menu-drop-enter-done {
  top: 40px;
  left: -256px;
  border-radius: 5px;
  width: 540px;
  height: auto;
  opacity: 1;
  background-color: #74baf0;
}

.menu-drop-exit {
  top: -7px;
  left: -256px;
  border-radius: 5px;
  width: 540px;
  height: auto;
  opacity: 1;
  background-color: #df0c0c00;
  transition: all 400ms;
}

.menu-drop-exit-active {
  top: -7px;
  left: -256px;
  border-radius: 5px;
  width: 540px;
  height: auto;
  opacity: 0;
  background-color: #df0c0c00;
  transition: all 500ms;
}

.menu-drop-internal {
  position: absolute;
  height: 130px;
  width: 460px;
  padding: 10px 20px;
  z-index: 60;
}

.dropdown-container {
  position: relative;
  height: 100%;
}

.btn-sub-nav {
  margin-top: 10px;
  margin-bottom: 10px;

  border-radius: 5px !important;
  font-weight: 500;
  border: none !important;
  outline: none !important;

  padding: 5px 10px;
  max-height: 36px;

  color: #ffffff !important;
  background: linear-gradient(135deg, #7fbae4, #6baee0);
  box-shadow: 5px 5px 15px #5fa1d1, -5px -5px 10px #97cdfa;

  background: linear-gradient(135deg, #7fbae4, #6baee0);
  box-shadow: 5px 5px 15px #338ac9, -5px -5px 15px #9ed1fa;
}

.btn-sub-nav:hover {
  background: linear-gradient(135deg, #7fbae4, #6baee0);
  box-shadow: 2px 2px 5px #5fa1d1, -8px -8px 20px #9ed1fa;
  transition: all 0.3s;
}

.btn-sub-nav:focus {
  background: linear-gradient(-135deg, #84c2ee, #57a2db);
  box-shadow: inset 2px 2px 10px #338ac9, inset -2px -2px 10px #9ed1fa;
  transition: all 0.3s;
}

.menu-drop-buttons-container {
  height: 100%;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.map-icon {
  height: 33px;
  width: auto;
  filter: contrast(120%) saturate(140%);
}

.map-button {
  height: auto !important;
  padding-top: 5px;
  padding-bottom: 4px;
  margin-right: 40px;

  background: linear-gradient(135deg, #a9d3f1, #82beec);
  box-shadow: 5px 5px 15px #338ac9, -5px -5px 15px #9ed1fa;
  transition: all 0.3s;
}

.map-button:hover .map-icon {
  animation: wiggle-wiggle-wiggle 0.42s ease-in-out;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.map-button:hover {
  background: linear-gradient(135deg, #9cc8e7, #95d0fd);
  box-shadow: 2px 2px 10px #338ac9, -2px -2px 10px #9ed1fa;
  transition: all 0.3s;
}

.col-head-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.call-btn-pad {
  padding: 6.5px 20px;
}

@media (max-width: 1580px) {
  /*reduce sizes a little bit*/
  .map-icon {
    height: 26px;
  }

  .call-btn-pad {
    padding: 7px 15px;
  }

  .header-container {
    height: 72px;
  }
}

@media (max-width: 1490px) {
  .header-right {
    margin-top: 20px;
    padding-left: calc(100% - 650px);
  }

  .call-btn {
    padding: 7px 15px;
  }

  .header-left {
    padding-left: 25px;
    padding-right: 25px;
  }

  /* .header-container {
        height: 127px;
    } */

  .map-button {
    margin-left: 12px;
    margin-right: 12px;
  }

  .nav-container {
    right: 150px !important;
  }
}

.coll-nav-container {
  position: absolute;
  top: 120px;
  background-color: rgba(112, 177, 228);
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  z-index: -1;
  text-align: left;
  width: 50%;
}

.menu-button {
  display: none;
  height: auto !important;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 20px;

  background-color: #f3f3f3;
  box-shadow: 5px 5px 15px #338ac9, -5px -5px 15px #9ed1fa;
  transition: all 0.3s;
}

.menu-top {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-bottom: 0px;
  padding-right: 10px;
}

.menu-title {
  font-size: 15px;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  color: white;
}

.menu-close {
  cursor: pointer;
}

@media (max-width: 870px) {
  /*Collapse everything and get to phone mode*/
  .header-left {
    display: none;
  }

  .header-right {
    margin-top: 0;
    padding-left: 30px;
    padding-right: 70px;
    text-align: left;
  }

  .call-btn-pad > img {
    display: none;
  }

  .phn-container {
    display: inline-block;
    position: absolute;
    top: 15px;
  }

  .phn-container-1 {
    display: inline-block;
    position: absolute;
    top: 70px;
  }

  .nav-container {
    margin-top: 65px;
  }

  .header-container {
    height: 124px;
  }

  .call-btn-B {
    display: inline-block;
    margin-top: 10px;
    display: block;
  }

  .header-padding {
    padding-top: 7px;
    padding-bottom: 40px;
  }

  .header-container {
    height: 115px;
  }

  .coll-nav {
    position: absolute;
    /* right: 45px; */
    left: 3%;
    z-index: 3;
  }

  .map-button {
    position: absolute;

    /* right: 5px; */
    left: 10%;
    z-index: 4;
    margin-top: 65px;
    /* right: 10px; */
  }

  .phn-container {
    padding-right: 30px;
    right: 0px;
    /* z-index: 3; */
  }

  .phn-container-1 {
    padding-right: 30px;
    right: 0px;
    /* z-index: 3; */
  }

  .btn-logo {
    /* right: -30px; */
    left: 0px;
    text-align: left !important;
    margin-right: 0;
    background-repeat: no-repeat;
    background-position: -17px 0;
    z-index: 2;
  }

  .menu-drop-internal {
    height: 350px;
    width: 360px;
    padding: 5px 30px;
  }

  .menu-drop-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
  }

  .btn-sub-nav {
    display: block !important;
  }

  .coll-nav-container {
    top: 170px;
  }

  .btn-logo {
    position: absolute;
    top: 25px;
    right: -130px;
  }

  .resp-block {
    display: block !important;
    margin-top: 15px;
    width: fit-content;
  }

  .menu-button {
    display: inline-block;
    margin-right: 300px;
  }

  .header-container {
    height: 72px;
  }

  .menu-drop-enter-active {
    top: 70px;
  }

  .menu-drop-enter-done {
    top: 20px;
    left: 0px;
  }
}

.nav-container {
  display: inline-block;
}

.phn-container {
  display: inline-block;
  text-align: right;
}

.phn-container-1 {
  display: inline-block;
  text-align: right;
}

@media (max-width: 740px) {
  .phn-container {
    display: inline-block;
    position: absolute;
    top: 15px;
  }

  .phn-container-1 {
    display: inline-block;
    position: absolute;
    top: 70px;
    z-index: 999;
  }

  .nav-container {
    margin-top: 65px;
  }

  .header-container {
    height: 124px;
  }

  .call-btn-B {
    display: inline-block;
    margin-top: 10px;
    display: block;
  }

  .header-padding {
    padding-top: 7px;
    padding-bottom: 40px;
  }

  .header-container {
    height: 115px;
  }

  .coll-nav {
    position: absolute;
    /* right: 45px; */
    left: 3%;
    z-index: 3;
  }

  .map-button {
    position: fixed;

    /* right: 5px; */
    left: 15%;
    z-index: 4;
    margin-top: 65px;
    /* right: 10px; */
  }

  .phn-container {
    padding-right: 0px;
    right: 0px;
    /* z-index: 3; */
  }

  .phn-container-1 {
    padding-right: 0px;
    right: 0px;
    /* z-index: 3; */
  }

  .btn-logo {
    /* right: -30px; */
    left: 0px;
    text-align: left !important;
    margin-right: 0;
    background-repeat: no-repeat;
    background-position: -17px 0;
    z-index: 2;
  }

  .menu-drop-internal {
    height: 350px;
    width: 290px;
    padding: 5px 30px;
  }

  .menu-drop-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
  }

  .btn-sub-nav {
    display: block !important;
  }

  .coll-nav-container {
    top: 35px;
  }
}

@media (max-width: 390px) {
  /* .phn-container::before {
        content: "8940401792";
    } */
  .map-button {
    left: 15%;
  }
}

a:link {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.coll-menu-drop-enter {
  left: -300px;
  border-radius: 5px;
  width: 260px;
  transition: all 0.4s;
}

.coll-menu-drop-enter-active {
  left: -300px;
  border-radius: 5px;
  width: 260px;
  transition: all 0.4s;
}

.coll-menu-drop-enter-done {
  left: 0px;
  border-radius: 5px;
  width: 100%;
  transition: all 0.4s;
}

.coll-menu-drop-exit {
  left: -250px;
  border-radius: 5px;
  width: 260px;
  transition: all 0.4s;
}

.coll-menu-drop-exit-active {
  left: -300px;
  border-radius: 5px;
  width: 260px;
  transition: all 0.4s;
}