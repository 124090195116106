@keyframes flip {
    0% { transform: rotate(0deg); }
    60% { transform: rotate(180deg); }
    100% { transform: rotate(0deg); }
}

@keyframes wiggle-wiggle-wiggle {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(-15deg); }
    20% { transform: rotate(0deg); }
    30% { transform: rotate(15deg); }
    40% { transform: rotate(0deg); }
    50% { transform: rotate(-15deg); }
    60% { transform: rotate(0deg); }
    70% { transform: rotate(15deg); }
    80% { transform: rotate(0deg); }
    90% { transform: rotate(-15deg); }
    100% { transform: rotate(0deg); }
}

.hover-shake:hover img{
    animation: wiggle-wiggle-wiggle 0.42s ease-in-out;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}