.customInteriors-img-overlay {
    position: absolute;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.596) 0%, rgba(0, 0, 0, 0) 60%);
    bottom: 0;
    right: 0;
    color: #ffffff;
    width: 100%;
    padding: 10px 30px;
    padding-top: 60px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

.navsection {
    height: 2px;
    width: 100%;
    right: 0px;
    background: #c05252;
    opacity: 0;
    position: absolute;
    top: -40px;
}