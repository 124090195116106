.glassStairs-base {
  padding: 40px 35px;
  background-color: rgb(235, 238, 238);
  display: flex;
  justify-content: space-between;
  position: relative;
}

.glassStairsA {
  width: auto;
  padding-top: 10px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1.2fr);
  grid-gap: 40px;
  padding-left: 10px;
}

.glassStairsB {
  padding-top: 100px !important;
  width: auto;
  min-width: 45%;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  text-align: right;
}

.imgB-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.glassStairsA > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.glassStairs-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(10px);
  opacity: 0.7;
  border-radius: 5px;
}

.glassStairs-img-top {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: -10px;
  right: 10px;
  border-radius: 5px;
}

.glassStairs-img-overlay {
  position: absolute;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.596) 0%,
    rgba(0, 0, 0, 0) 60%
  );
  bottom: 10px;
  right: 10px;
  border-radius: 0 0 5px 5px;
  color: #ffffff;
  width: 100%;
  padding: 10px 30px;
  padding-top: 60px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.glassStairs-title-container > span {
  font-size: 90px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  color: #3e7e78;
}

.glassStairs-title-container {
  margin-top: -25px;
}

.glassStairs-subContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 10px;
}

.glassStairs-subtitle > div {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
  font-family: "Open Sans", sans-serif;
  color: #8caca8;
  text-align: right;
}

.glassStairs-subtitle {
  margin-top: 25px;
}

.glassStairs-content {
  font-size: 22px;
  color: #485554;
  max-width: 720px;
  margin-top: 25px;
  padding-left: 40px;
}

.gsB {
  display: none;
}

.glassStairs-residenceBtnContainer {
  margin-top: 40px;
}

.residenceExtraText {
  font-size: 21px;
  color: #6d7a79;
  padding-bottom: 10px;
}

.residenceExtraText > span {
  color: #546866;
}

.glassStairs-btn-toResidence {
  padding: 5px 13px;
  font-family: "Open Sans", sans-serif;
  font-size: 23px;
  border: none;
  background-color: rgb(109, 154, 194);
  border-radius: 5px;
  color: #ffffff;
  transition: all 0.4s;
}

.glassStairs-btn-toResidence:hover {
  background-color: rgb(127, 174, 216);
  transition: all 0.3s;
}

/* Breakpoints */
@media (max-width: 1600px) {
  .glassStairsB {
    min-width: 37%;
  }

  .glassStairs-title-container > span {
    font-size: 73px;
  }

  .glassStairs-title-container {
    padding-left: 30px;
  }

  .glassStairs-subtitle > div {
    font-size: 30px;
  }

  .glassStairs-content {
    font-size: 19px;
  }

  .residenceExtraText {
    font-size: 19px;
    padding-left: 20px;
  }
}

@media (max-width: 1300px) {
  .glassStairs-base {
    flex-direction: column-reverse;
    padding: 65px 35px;
    padding-bottom: 30px;
  }

  .glassStairsB {
    padding-top: 0 !important;
    padding-right: 13px;
    position: relative;
  }

  .glassStairs-title-container {
    text-align: left;
    padding-left: 0;
    margin: 0;
  }

  .glassStairs-subContainer {
    flex-direction: row;
    align-items: center;
    margin-top: 14px;
    justify-content: space-between;
    padding: 0;
  }

  .glassStairs-subContainer > div {
    margin: 0;
  }

  .glassStairs-content {
    padding-top: 20px;
  }

  .glassStairs-subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    min-width: 50%;
  }

  .glassStairs-subtitle > div {
    text-align: left;
  }

  .glassStairs-residenceBtnContainer {
    margin-top: 10px;
    text-align: right;
  }

  .residenceExtraText {
    padding: 0;
    padding-bottom: 5px;
  }

  .gsA {
    display: none;
  }

  .gsB {
    display: block;
  }

  .glassStairs-title-container > span {
    display: block;
    padding-right: 20px;
  }

  .glassStairs-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .glassStairs-btn-toResidence {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .glassStairs-base {
    padding: 20px 20px;
  }

  .glassStairsA {
    grid-template-columns: minmax(0, 1fr);
  }

  .gsA {
    display: block;
  }

  .gsB {
    display: none;
  }

  .glassStairs-title-container {
    justify-content: center;
    text-align: center;
  }

  .glassStairs-subContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .glassStairs-subtitle {
    align-items: center;
  }

  .glassStairs-content {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 13px;
  }

  .glassStairs-residenceBtnContainer {
    text-align: center;
    padding-top: 14px;
  }

  .glassStairsA {
    grid-gap: 20px;
    padding-bottom: 30px;
  }

  .glassStairsB {
    padding-right: 0;
    padding-bottom: 25px;
  }
}

@media (max-width: 600px) {
  .glassStairs-title-container > span {
    font-size: 11vw;
    padding-right: 0px;
  }

  .glassStairs-subtitle > div {
    font-size: 5.5vw;
  }

  .glassStairs-content {
    font-size: 16px;
  }

  .residenceExtraText {
    font-size: 16px;
  }

  .glassStairs-btn-toResidence {
    font-size: 16px;
  }

  .glassStairsB {
    padding-right: 0;
  }

  .glassStairsA {
    padding-bottom: 0;
  }
}

@media (max-width: 450px) {
  .glassStairsB {
    padding-bottom: 7px;
  }

  .glassStairs-residenceBtnContainer {
    padding-top: 10px;
  }

  .glassStairs-content {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
