.hero2-base {
  height: 630px;
  display: flex;
  background-color: #8da5a2;
  text-align: left;
}

.hero2-A {
  height: 100%;
  width: 47%;
  background-color: rgb(240, 240, 240);
  position: relative;
  display: inline-block;
  z-index: 5 !important;
}

.hero2-B {
  height: 100%;
  width: 53%;
  position: relative;
  display: inline-block;
  z-index: 5 !important;
}

.stair-text-container {
  position: absolute;
  right: 20px;
  top: 40px;
  z-index: 40;
  text-align: right;
  z-index: 60 !important;
}

.stair-text-content {
  color: #546866;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  width: 400px;
  position: absolute;
  right: 20px;
  bottom: 50px;
  text-align: right;
  font-weight: 300;
}

.content-stair-top {
  color: #3e7e78;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  line-height: 0.8;
  font-size: 100px !important;
  padding-bottom: 5px;
}

.content-stair-bottom-container {
  color: #9ecac7;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-size: 45px !important;
  padding-bottom: 5px;
  line-height: 1.2;
  padding: 30px 0;
}

.highlightA {
  color: #89b3b0;
}

.img-stairs {
  z-index: -1;
  position: absolute;
  height: 620px;
  left: 0px;
  top: 0px;
}

.stairDisp {
  display: none;
}

.btn-stairs {
  font-weight: 500;
  border: none;
  padding: 3px 30px !important;
  font-size: 30px !important;
  font-weight: 500;
  border-radius: 50px !important;
  color: #ffffff !important;
  background-color: #8da5a2 !important;
  margin-top: 2%;
  transition: all 0.4s;
  z-index: 40;
}

.btn-stairs:hover {
  background-color: #ffffff !important;
  color: #147d76 !important;
  transition: all 0.4s;
  border: #546866 !important;
}

.resp-show {
  display: none;
}

.bedFade {
  position: absolute;
  top: 40px;
  right: -20px;
  height: 570px;
  width: auto;
  z-index: -1;
}

.resp-show2 {
  display: none;
}

.bed-text-container {
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: 40;
}

.bed-text-top {
  color: #ececec;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  line-height: 1.03;
  font-size: 100px !important;
}

.bed-text-subtop {
  color: #ececec;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 7px;
  padding-left: 4px;
  padding-top: 15px;
  font-size: 29px !important;
}

.btn-bed {
  font-weight: 500;
  border: none;
  color: #ffffff;
  padding: 3px 30px !important;
  font-size: 30px !important;
  font-weight: 500;
  border-radius: 50px !important;
  color: #1faaa0 !important;

  margin-top: 50px;
  transition: all 0.4s;
}

.btn-bed:hover {
  color: #ffffff !important;
  transition: all 0.4s;
  border-color: #679f99;
  background-color: #679f99 !important;
}

.bed-text-content {
  color: #e7e7e7e0;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  width: 460px;
  position: absolute;
  left: 20px;
  bottom: 50px;
  text-align: left;
  font-weight: 400;
  text-align: left !important;
}

.bed-lowlight {
  color: #dddddd;
  opacity: 0.5;
}

/*Overall Breakpoints*/
@media (max-width: 1500px) {
  .hero2-A {
    display: block;
    width: 100%;
    height: 640px;
  }

  .hero2-B {
    display: block;
    width: 100%;
    height: 665px;
  }

  .hero2-base {
    height: auto;
    display: block;
  }
}

/*Breakpoints for the stairs section*/
@media (max-width: 1770px) {
  .img-stairs {
    height: 620px;
    left: 0px;
    top: 0px;
  }

  .content-stair-top {
    font-size: 90px !important;
    margin-top: -5px;
  }

  .stair-text-content {
    width: 350px;
    bottom: 30px;
  }

  .content-stair-bottom-container {
    font-size: 40px !important;
  }

  .btn-stairs {
    margin-top: 20px;
  }
}

@media (max-width: 1670px) {
  .img-stairs {
    left: 0px;
  }

  .stair-text-content {
    width: 290px;
    bottom: 30px;
    right: 23px;
  }

  .btn-stairs {
    margin-top: 5px;
  }
}

@media (max-width: 850px) {
  .content-stair-top {
    font-size: 80px !important;
    margin-top: -15px;
  }

  .content-stair-bottom-container {
    font-size: 35px !important;
    padding-top: 25px;
  }

  .img-stairs {
    height: 560px;
  }

  .hero2-A {
    height: 590px;
  }
}

@media (max-width: 690px) {
  .stair-text-container {
    top: 40px;
    left: 0;
    right: 20px;
  }

  .content-stair-bottom-container {
    font-size: 30px !important;
    text-align: right;
  }

  .content-stair-top {
    font-size: 65px !important;
  }

  .img-stairs {
    top: 0px;
  }

  .stair-text-content {
    text-align: center;
    margin: 0;
    bottom: 30px;
    left: 0;
    right: 0;
    padding: 0 20px;
    width: 100%;
  }

  .hero2-A {
    height: 715px;
  }

  .img-stairs {
    display: none;
  }

  .stairDisp {
    display: block;
  }
}

@media (max-width: 570px) {
  .img-stairs {
    top: 100px;
    left: -20px;
    height: 500px;
  }

  .hero2-A {
    height: 795px;
  }

  .stair-text-container {
    text-align: right;
    top: 40px;
    right: 0px;
    left: 0px;
  }

  .content-stair-top {
    font-size: 60px !important;
    text-align: right;
    padding-right: 20px;
  }

  .content-stair-bottom-container {
    margin-right: 20px;
  }

  .btn-stairs {
    margin-right: 20px;
    padding: 6px 20px !important;
    font-size: 22px !important;
    margin-top: -5px;
  }
}

/*Breakpoints for the Bed section*/
@media (max-width: 1895px) {
  .bedFade {
    top: 73px;
    right: -20px;
    height: 500px;
  }
}

@media (max-width: 1770px) {
  .bedFade {
    top: 30px;
    right: -20px;
    height: 550px;
  }

  .bed-text-top {
    font-size: 85px !important;
  }

  .bed-text-content {
    width: 430px;
    bottom: 30px;
  }

  .resp-show {
    display: block !important;
  }

  .bed-text-top {
    display: none;
  }

  .bed-text-subtop {
    width: 300px;
  }

  .btn-bed {
    margin-top: 4%;
  }
}

@media (max-width: 1600px) {
  .bed-text-content {
    width: 430px;
    bottom: 30px;
  }

  .btn-bed {
    margin-top: 30px;
    font-size: 26px !important;
  }
}

@media (max-width: 1500px) {
  .bedFade {
    position: absolute;
    top: -10px;
    right: -20px;
    height: 700px;
    width: auto;
  }

  .bed-text-content {
    left: 20px;
    margin-top: 50px;
    width: 450px;
  }

  .btn-bed {
    margin-top: 50px;
  }
}

@media (max-width: 1050px) {
  .bedFade {
    top: 15px;
    height: 560px;
    right: -15px;
  }

  .bed-text-content {
    width: 50vw;
    bottom: 25px;
  }

  .btn-bed {
    margin-top: 35px;
  }

  .hero2-B {
    display: block;
    width: 100%;
    height: 600px;
  }
}

@media (max-width: 920px) {
  .bedFade {
    top: 30px;
    height: 530px;
  }

  .bed-text-content {
    width: 52vw;
    bottom: 30px;
  }

  .hero2-B {
    height: 640px;
  }
}

@media (max-width: 790px) {
  .hero2-B {
    height: 550px;
  }

  .bed-text-content {
    width: 280px;
    left: 20px;
  }

  .resp-show {
    display: none !important;
  }

  .resp-show2 {
    display: block;
  }

  .bed-text-top {
    font-size: 10vw !important;
  }

  .bed-text-subtop {
    width: auto;
    padding-top: 10px;
  }

  .btn-bed {
    margin-top: 30px;
  }

  .bedFade {
    height: 500px;
    top: 100px;
  }
}

@media (max-width: 760px) {
  .bed-text-content {
    width: 250px;
    bottom: 20px;
  }

  .hero2-B {
    height: 580px;
  }

  .bedFade {
    height: 450px;
  }
}

@media (max-width: 690px) {
  .bed-text-content {
    width: auto;
    left: 20px;
    right: 20px;
    text-align: center;
    bottom: 30px;
  }

  .hero2-B {
    height: 670px;
  }

  .bedFade {
    height: 510px;
    top: 90px;
  }
}

@media (max-width: 650px) {
  .bed-text-content {
    width: auto;
    left: 20px;
    right: 20px;
    text-align: center;
    bottom: 30px;
  }

  .hero2-B {
    height: 780px;
  }

  .bedFade {
    height: 510px;
    top: 160px;
    left: -15px;
    right: 0;

    transform: rotateY(180deg);
  }

  .bed-text-subtop {
    font-size: 4.5vw !important;
  }
}

@media (max-width: 500px) {
  /* Stairs */
  .stair-text-container {
    position: static;
  }

  .hero2-A {
    height: auto;
  }

  .content-stair-top {
    font-size: 12vw !important;
    padding-top: 15px;
    padding-bottom: 0;
  }

  .content-stair-bottom-container {
    font-size: 5.5vw !important;
    padding-top: 15px;
    padding-bottom: 0;
  }

  .btn-stairs {
    font-size: 19px !important;
    margin-top: 15px;
    padding: 5px 15px !important;
  }

  .img-stairs {
    top: 50px;
    width: 100%;
    height: auto;
  }

  .stair-text-content {
    font-size: 16px;
    position: static;
    padding-bottom: 10px;
    margin: 0;
    margin-top: 85%;
  }

  /* Bed Stuff */
  .hero2-B {
    height: auto;
  }

  .bed-text-container {
    position: static;
    padding-left: 20px;
    padding-top: 15px;
  }

  .bedFade {
    height: 105vw;
    top: -75px;
  }

  .btn-bed {
    font-size: 20px !important;
    margin-top: 10px;
  }

  .bed-text-content {
    font-size: 16px !important;
    position: static;
    width: 100%;
    padding: 0 15px;
    margin-top: -130px;
    padding-bottom: 10px;
  }

  .bed-text-container {
    top: 15px;
  }

  .bed-text-subtop {
    padding: 0;
  }
}
